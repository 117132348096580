import React from "react";

// React
import { useState } from "react";

// Shared Components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo";
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import CustomToast from "../components/shared/toast/CustomToast";
import toast from "react-hot-toast";

// Service
import { contactSubmission } from "../services/contactAPI";

// Images
import ComingSoon from "../images/contact/coming-soon.png";

// SVG
import Backdrop from "../images/resources/compatible-central-stations/backdrop.inline.svg";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [interestFormSent, setinterestFormSent] = useState(false);

  //email sent from contact form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const email = event.target.email.value;
    const firstName = event.target.firstName.value;
    const lastName = event.target.lastName.value;
    const phone = event.target.phone.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;

    let postBody = JSON.stringify({
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Subject: subject,
      Message: message,
      PageName: "Contact",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        setinterestFormSent(true);
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"success"}
              title={"Success!"}
              message={
                "Thank you for your interest in Tech Tester! Please check your inbox for updates."
              }
            />
          ),
          { duration: 3000 }
        );
        setLoading(false);
        event.target.reset();
      } else {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={"Something went wrong. Please try again."}
            />
          ),
          { duration: 3000 }
        );
        setLoading(false);
        event.target.reset();
      }
    });
  };

  return (
    <div>
      <Seo
        title="Contact"
        description="Contact Tech Tester support with any questions or concerns you may have."
      />
      <Header />
      {/* Hero section */}
      <div className="relative bg-myGray-300">
        <div
          className={
            "relative z-10 pt-24 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"
          }
        >
          <div className={"my-auto text-left "}>
            <h1 className={"md:text-5xl text-4xl text-myGray-600 font-bold"}>
              Coming very soon
            </h1>
            <h3 className={"text-myGray-600 text-lg pt-10"}>
              We are currently hard at work to get Tech Tester up and running.
              If you are interested in being notified about our progress or have
              any questions at all, please contact us.
            </h3>
          </div>
          <div className={"m-auto relative"}>
            <img className="z-10 relative" src={ComingSoon} alt="Temp Item" />
          </div>
        </div>
        <Backdrop
          className={
            "fill-myGray-250 object-scale-down absolute z-2 top-36 md:top-0 right-10 md:right-52 lg:right-96 h-80 w-auto"
          }
        />
      </div>
      {/*END HERO*/}

      {/*START FORM*/}
      <div className="relative overflow-hidden items-center justify-center  bg-myGray-200">
        <div
          aria-hidden="true"
          className="absolute z-0 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
        />

        <h1
          className={"text-center relative z-10 mt-32 mx-4 font-bold text-3xl"}
        >
          Get in touch! We'll get back to you as soon as we can.
        </h1>
        <div className="max-w-3xl z-10 mx-6 md:mx-auto my-20">
          <form onSubmit={handleSubmit} className="relative h-full w-full">
            <div className="relative w-full mt-10 space-y-4">
            <div className="relative flex justify-between space-x-2">
                <label className="relative w-full" htmlFor={"firstname"}>
                  <input
                    type="text"
                    className="text-left appearance-none block w-full px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                    placeholder="First Name *"
                    required
                    id="firstName"
                    name="firstName"
                  />
                </label>

                <label className="relative w-full" htmlFor={"lastname"}>
                  <input
                    type="text"
                    className="text-left appearance-none block w-full px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                    placeholder="Last Name *"
                    required
                    name="lastName"
                    id={"lastName"}
                  />
                </label>
              </div>
              <div className="relative flex justify-between space-x-2">
                <label className="relative w-full" htmlFor={"email"}>
                  <input
                    type="email"
                    className="text-left appearance-none block w-full px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                    placeholder="Email Address *"
                    required
                    name="email"
                    id="email"
                  />
                </label>

                <label className="relative w-full" htmlFor={"phone"}>
                  <input
                    type="text"
                    className="text-left appearance-none block w-full px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                    placeholder="Phone Number"
                    name="phone"
                    id={"phone"}
                  />
                </label>
              </div>
                <label className="relative flex" htmlFor={"subject"}>
                  <input
                    type="text"
                    className="text-left appearance-none block w-full px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                    placeholder="Subject"
                    name="subject"
                    id={"subject"}
                  />
                </label>
              <div className="relative">
                <label htmlFor={"message"}>
                  <textarea
                    type="textarea"
                    className="text-left appearance-none block w-full h-48 px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                    placeholder="Message"
                    name="message"
                    id={"message"}
                  />
                </label>
              </div>
              <div className="relative">
                {loading ? (
                  <LoadingButton type={"button"} />
                ) : (
                  <button
                    className={`w-1/4 flex justify-center px-5 py-3 border border-transparent rounded-md shadow-sm text-sm  font-bold ${
                      interestFormSent
                        ? "bg-greenTT text-white"
                        : "bg-yellowTT hover:bg-yellowTT-alt text-black"
                    }`}
                  >
                    {interestFormSent ? "SENT" : "SUBMIT"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className={"relative z-10 my-24 px-10 mx-auto max-w-5xl"}>
          <h1 className={"text-center relative z-10 pb-6 font-bold text-3xl"}>
            Are you from a Central Station or monitoring software company
            looking to integrate with us?
          </h1>
          <h4 className={"text-center relative z-10 text-xl"}>
            Contact the team at{" "}
            <span className={"hover:underline"}>
              <a href={"mailto:support@alarmtechtester.com"}>
                support@alarmtechtester.com
              </a>
            </span>
          </h4>
        </div>
      </div>
      {/*END FORM*/}
      <Footer />
    </div>
  );
}
